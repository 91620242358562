import React, { Fragment, useState } from "react";
import { Accordion, AccordionHeader, AccordionBody, } from "@material-tailwind/react";


function Icon({ id, open }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={`${id === open ? "rotate-180" : ""
                } h-7 w-7 transition-transform`}
            fill="none"
            viewBox="0 0 24 24"
            stroke="#fbbd69"
            strokeWidth={2}
        >
            <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
        </svg>
    );
}


const CommonQuestion = () => {
    const [open, setOpen] = useState(0);

    const handleOpen = (value) => {
        setOpen(open === value ? 0 : value);
    };

    return (
        <>
            <section className="tv-section8">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">Some common questions</h2>
                    <div className="max-width1000">
                        <Fragment>
                            <Accordion open={open === 1} icon={<Icon id={1} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(1)}>
                                    When I switch to Purple Cow will my internet quality decrease?
                                </AccordionHeader>
                                <AccordionBody>
                                    Not at all. It is the same internet as the big guys. Our internet is awesome and we have tens of thousands of herd members to prove it.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 2} icon={<Icon id={2} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(2)}>
                                    How is it possible that Purple Cow Internet is so much less expensive?
                                </AccordionHeader>
                                <AccordionBody>
                                    Purple Cow Internet's goal is to drive down the cost of internet so we look for efficiencies where we can to make this happen.  
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 3} icon={<Icon id={3} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(3)}>
                                    Is Purple Cow Internet's service reliable?
                                </AccordionHeader>
                                <AccordionBody>
                                    Very much so. We have invested into state of the art equipment and double up on everything within our data centers to ensure redundancy. We have a 99.999% up time and will continue to investing into our equipment to make sure you have the best wifi experience.  
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 4} icon={<Icon id={4} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(4)}>
                                    Does Purple Cow have a referral program?
                                </AccordionHeader>
                                <AccordionBody>
                                Totally! Any friend you refer you receive $25 and they get $15. Once your internet is live you will receive a link that can be shared with friends and family. All they do is just signup as normal with your link and both of you will get the credit.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 5} icon={<Icon id={5} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(5)}>
                                    How does billing work?
                                </AccordionHeader>
                                <AccordionBody>
                                    Billing is done on a credit card or visa debit card just like Netflix or other subscription sites. When you signup a charge is made on your card for the first month and setup. The date your internet turns on becomes your monthly bill date.
                                </AccordionBody>
                            </Accordion>
                            <Accordion open={open === 6} icon={<Icon id={6} open={open} />}>
                                <AccordionHeader onClick={() => handleOpen(6)}>
                                    Do I need to cancel with my other provider?
                                </AccordionHeader>
                                <AccordionBody>
                                    Yes, once you have the cow’s internet setup you will cancel with your other provider.
                                </AccordionBody>
                            </Accordion>
                        </Fragment>
                    </div>
                </div>
            </section>
        </>
    )
}

export default CommonQuestion
